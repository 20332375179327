@use '../../styles/globals';
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.about {
    width: 100%;
    margin-top: $spacing-6;
    max-width: 500px;
    margin: 0 auto;
    @include tablet {
        margin-top: $spacing-10;
    }
    &__paragraph {
        padding-bottom: $spacing-3;
        font-size: 16px;
    }

    &__header {
        font-size: 16px;
        padding-bottom: 16px;

    }
}