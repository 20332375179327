@use '../../styles/globals';
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;


.work-card {
    @include flex-column;
    gap: $spacing-4;
    padding-top: $spacing-2;
    &__title {
        @include header;
        font-size: 24px;
        
    }


    &__title-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-2;
        @include desktop {
            flex-direction: row;
        }
        justify-content: space-between;
        
        
    }

    &__title-row-and-info {
        @include flex-column;
        gap: $spacing-2;
        
    }

    &__links {
        display: flex;
        gap: $spacing-3;
        
    }


    &__img {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.04);
    }
    &__link {
        font-family: Din;
        font-weight: 700;
        color: #4197ff;
        &:hover {
            text-decoration:underline;
        }
    }

    &__info {
        font-family: Din;
        font-weight: 400;
        font-size: 16px;
        
        
    }

    &__stack {
        padding-bottom: $spacing-2;
    }
    
}