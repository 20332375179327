

@use './variables' as *;
@use './typography' as *;

// SCREENSIZING MIXINS

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

//UTILITY 



@mixin flex-column() {
    display: flex;
    flex-direction: column;
}

@mixin header() {
    font-family: Din;
    font-weight: 700;
}
