@use '../../styles/globals';
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

iframe {
    
    
    width: 100%;

    @include tablet {
        height: 500px;
    }
    
    
}

.video-wrapper {
    
    
    position: relative;
}